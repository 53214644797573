<script setup lang="ts">
interface Props {
  groupPosition?: "left" | "middle" | "right" | undefined
  color?:
    | "primary"
    | "secondary"
    | "positive"
    | "negative"
    | "danger"
    | "header"
  size?: "small" | "medium" | "large"
  icon?: boolean
  disabled?: boolean
  fullWidth?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  groupPosition: undefined,
  color: "primary",
  size: "medium",
  icon: false,
  disabled: false,
  fullWidth: false,
})

const colorClasses: { [K in NonNullable<Props["color"]>]: string } = {
  primary:
    "bg-indigo-500 text-white border-transparent hover:bg-indigo-700 focus:ring-indigo-500",
  secondary:
    "bg-white text-slate-600 border-slate-200 hover:border-slate-300 focus:ring-slate-200",
  positive:
    "bg-emerald-500 text-white border-transparent hover:bg-emerald-600 focus:ring-emerald-500",
  negative:
    "text-red-500 border-red-500 hover:border-red-700 focus:ring-red-500",
  danger:
    "bg-red-500 text-white border-transparent hover:bg-red-700 focus:ring-red-700",
  header:
    "text-indigo-300 border-indigo-400 hover:border-indigo-300 hover:text-white focus:ring-indigo-300",
}

const sizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "h-6 leading-6 text-xs py-0 px-2",
  medium: "h-8 leading-8 text-xs py-0 px-3",
  large: "h-12 leading-12 text-m py-0 px-3",
}

const disabledColors: { [K in NonNullable<Props["color"]>]: string } = {
  primary: "disabled:hover:bg-indigo-500",
  secondary: "disabled:hover:border-slate-200",
  positive: "disabled:hover:bg-emerald-500",
  negative: "disabled:hover:border-red-500",
  danger: "disabled:hover:bg-red-500",
  header: "disabled:hover:border-indigo-400 disabled:hover:text-indigo-300",
}

const iconSizeClasses: { [K in NonNullable<Props["size"]>]: string } = {
  small: "w-6 h-6 p-0 justify-center",
  medium: "w-8 h-8 p-0 justify-center",
  large: "w-12 h-12 p-0 justify-center",
}

const classes = computed(() => {
  const classes = [colorClasses[props.color]]
  classes.push(
    props.icon ? iconSizeClasses[props.size] : sizeClasses[props.size]
  )
  if (props.disabled) {
    // most classes are static with modifier, only inject dynamic ones here
    classes.push(disabledColors[props.color])
  }
  if (props.fullWidth) classes.push("w-full flex justify-center")
  if (!props.groupPosition)
    classes.push("rounded focus:ring-2 focus:ring-offset-2")
  if (props.groupPosition === "left") classes.push("rounded-l")
  if (props.groupPosition === "right") classes.push("rounded-r")
  return classes.join(" ")
})
</script>

<template>
  <button
    type="button"
    class="font-medium inline-flex items-center border leading-4 disabled:opacity-30 disabled:cursor-not-allowed focus:outline-none"
    :class="classes"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>
